import Vue from 'vue'
import VueRouter from 'vue-router'

import StartPage from "@/components/StartPage";
import Contact from "@/components/Contact";
import Terms from "@/components/Terms";
import Fees from "@/components/Fees";
import Tutorial from "@/components/Tutorial";
import About from "@/components/About";
import Imprint from "@/components/Imprint";
import Farmer from "@/components/Farmer";

Vue.use(VueRouter)

const routes = [
    { path: '/', component: StartPage },
    { path: '/contact', component: Contact },
    { path: '/terms', component: Terms },
    { path: '/fees', component: Fees },
    { path: '/tutorial', component: Tutorial },
    { path: '/about', component: About },
    { path: '/imprint', component: Imprint },
    { path: '/detail.html', component: Farmer },
]

const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
})

export default router
