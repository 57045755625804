<template>
  <div>
    <!-- INTRO HEAD -->
    <div class="intro2" style="background: url(/images/bg/technologic-2021-08-26-15-47-23-utc.jpg) no-repeat center; background-size: cover;">
      <div class="overlay"></div>
      <div class="container">
        <div class="row center-content">
          <div class="col-md-12 text-center" style="margin-top: 5em; margin-bottom: 3em;">
            <h2>How to get started</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- INTRO HEAD -->
    <!-- INFO CONTENT -->
    <div class="info-content">
      <div class="container">
        <div class="row">

          <div class="container col-md-12 pt-5">
            <h3>Our Video-Guide: How to start pool-farming</h3>
            <div class="video-container">
              <iframe src="https://www.youtube.com/embed/gFgJMDDO_nA" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="container col-md-12 pt-5" style="margin-top: 50px;">
            <h3>Our Text-Guide:</h3>
            <p class="lead">
              1. First you need to install the Chia software.
            </p>
            <blockquote class="blockquote text-left pt-2">
              <p class="lead">Please use only the official code from Chia Network! You find this code on the official <a href="https://www.chia.net" target="_blank">Chia Network</a> page.</p>
            </blockquote>
            <p class="lead">Chia Network provides a very good and simple <a href="https://github.com/Chia-Network/chia-blockchain/wiki/INSTALL" target="_blank">installation guide</a>. Follow this guide to install the Chia software on your computer.</p>
          </div>
          <div class="container col-md-12 pt-5">
            <p class="lead">2. Get some mojos (mojo = smallest chia amount)</p>
            <p class="lead">Once you created your keys and wallet and you are synced with the network, you can create your plotnft for pooling. For this you need one (1) mojo. To get some free mojos you can use our faucet by <a href="https://discord.gg/Rb5hAMtB2Z" target="_blank">joining our Discord</a> and use #faucet channel. Or you can use the official <a href="https://faucet.chia.net/" target="_blank">Chia Network faucet</a>.</p>
              <p class="lead">3. Create your plotnft via GUI or CLI.</p>
              <div class="container py-2">
                <strong class="lead" style="font-weight: bolder">GUI</strong>
                <p class="lead white">Select Pool in the menu. Next click on "JOIN A POOL".</p>
                <p class="lead white">Select the "Connect to pool" option and enter "https://pool.chiahub.io" into the "Pool URL" field. If you receive an error, ensure that the url is as above and there is no trailing slash ("/").</p>
                <p class="lead white">Now you need to wait until our pool details show up. Then click "CREATE" to join.</p>
                <p class="lead white">It will take a short amount of time to verify and commit your transaction and create the plotnft on the blockchain. After that time you will be able to see your plot nft in the GUI</p>
              </div>
              <div class="container bg-green mt-2 py-2">
                <string class="lead" style="font-weight: bolder">CLI</string>
                <p class="lead white">Create your plotnft via "chia plotnft create -u https://pool.chiahub.io -s pool"</p>
                <p class="lead white">It will take a shot amount of time to verify and commit your transaction and create the plotnft on the blockchain.</p>
                <p class="lead white">Do a "chia plotnft show" to check if the plotnft has been created.</p>
              </div>
          </div>
          <div class="container col-md-12 pt-5">
            <h3>Plotting</h3>
            <p class="lead">Now start plotting via GUI, CLI or buy pool-ready plots from our partner <a href="https://plotting.cloud/order?promo=chiahub" target="_blank">plotting cloud</a>.</p>
            <div class="container bg-green py-2">
              <strong class="lead" style="font-weight: bolder">GUI</strong>
              <p class="lead white">Select "Plots" in the menu and click on "ADD A PLOT". Use your most convenient settings. Select the created plotnft under "Join a Pool" and click create.</p>
            </div>
            <div class="container bg-green mt-2 py-2">
              <string class="lead" style="font-weight: bolder">CLI</string>
              <p class="lead white">You need to know your singleton address to create a pool-ready plot. To get you singleton use the following command: chia plotnft show</p>
              <p class="lead white">Now you can create the plot via "chia plots create -c" followed by your singleton address. Of course you can add more parameters to setup your plot create command.</p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Fees"
}
</script>

<style scoped>

</style>
