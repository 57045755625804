<template>
  <!-- HEADER -->
  <header>
    <nav class="navbar navbar-default navbar-fixed-top">
      <div class="container">
        <div class="navbar-header pull-left">
          <!-- Logo -->
          <a class="navbar-brand" href="/">chiahub.io</a>
        </div>
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <div class="top-contact pull-right editContent">
          &nbsp;
        </div>
        <!-- Navmenu -->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav navbar-right">
          <template v-if="currentPath === '/'">
            <li><a href="#page-top" class="page-scroll">Home</a></li>
            <li><a class="page-scroll" href="#services">Pool-Info</a></li>
            <li><a class="page-scroll" href="#info">Chia</a></li>
            <li><a class="page-scroll" href="#dual-info">Reasons</a></li>
            <li><a class="page-scroll" href="#features">Features</a></li>
            <li><a class="page-scroll" href="#testimonials">Testimonials</a></li>
          </template>
            <li class="dropdown">
              <a href="#" class="page-scroll"><i class="ti-menu"></i></a>
              <ul class="dropdown-menu" role="menu">
                <li><router-link to="/fees">Pool Fees</router-link></li>
                <li><router-link to="/terms">Terms & Conditions</router-link></li>
                <li><router-link to="/contact">Contact Us</router-link></li>
                <li><a href="https://kb.chiahub.io/portal/en/home" target="_blank">Support</a></li>
                <li><a href="https://kb.chiahub.io/portal/en/kb/articles/frequently-asked-questions" target="_blank">FAQ</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <!-- HEADER -->
</template>

<script>
export default {
  name: "Menu",
  computed: {
    currentPath() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>

</style>
