<template>
  <div>
    <!-- INTRO HEAD -->
    <div class="intro2" style="background: url(/images/bg/contact-and-communication-icons-on-yellow-backgrou-2021-08-28-09-35-37-utc.jpg) no-repeat center; background-size: cover;">
      <div class="overlay"></div>
      <div class="container">
        <div class="row center-content">
          <div class="col-md-12 text-center" style="margin-top: 5em; margin-bottom: 3em;">
            <h2>Imprint</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- INTRO HEAD -->
    <!-- INFO CONTENT -->
    <div class="info-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>Get in touch any time</h2>
            <p>bloxxum GmbH<br/>Kleyerstrasse 75-87<br/>60326 Frankfurt am Main<br/>GERMANY</p>
            <p>Email: <a href="mailto:info@bloxxum.eu">info@bloxxum.eu</a> </p>
            <p>We have licenced all pictures on our website, please contact us if you have any question</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imprint"
}
</script>

<style scoped>

</style>
