<template>
  <div>
    <!-- INTRO HEAD -->
    <div class="intro5" style="background: #333 url(../images/bg/1.jpg) no-repeat center; background-size: cover;">
      <div class="overlay"></div>
      <div class="container">
        <div class="row center-content">
          <div class="col-md-12 text-center" style="margin-top: 1em; margin-bottom: 1em;">
            <h2>Farmer</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- INTRO HEAD -->
    <!-- INFO CONTENT -->
    <div class="info-content">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            Launcher ID
          </div>
          <div class="col-md-9">
            {{ launcher_id }}
          </div>
          <div class="col-md-3" v-if="farm.nickname">
            Nickname
          </div>
          <div class="col-md-9" v-if="farm.nickname">
            {{ farm.nickname }}
          </div>
          <div class="col-md-3" v-if="farm.total_payout">
            Payout
          </div>
          <div class="col-md-9" v-if="farm.total_payout">
            <span v-if="loading">...</span>
            <span v-else>{{ new Intl.NumberFormat().format(farm.total_payout / (10**12)) }} XCH</span>
          </div>
          <div class="col-md-3" v-if="farm.unpaid_balance">
            Unpaid Balance
          </div>
          <div class="col-md-9" v-if="farm.unpaid_balance">
            <span v-if="loading">...</span>
            <span v-else>{{ new Intl.NumberFormat().format(farm.unpaid_balance / (10**12)) }} XCH</span>
          </div>
          <div class="col-md-3">
            Points
          </div>
          <div class="col-md-9">
            <span v-if="loading">...</span>
            <span v-else>{{ new Intl.NumberFormat().format(farm.points) }} / {{ new Intl.NumberFormat().format(farm.total_points) }}</span>
          </div>
          <div class="col-md-3">
            Share (Rank)
          </div>
          <div class="col-md-9">
            <span v-if="loading">...</span>
            <span v-else>{{ new Intl.NumberFormat().format(farm.share*100) }} % ({{ new Intl.NumberFormat().format(farm.rank) }})</span>
          </div>
          <div class="col-md-3">
            Est. Space <span v-if="farm.space_actual">(Act. Space)</span>
          </div>
          <div class="col-md-9">
            <span v-if="loading">...</span>
            <span v-else>{{ farm.space | prettyBytesIntl(1, true) }} <span v-if="farm.space_actual">({{ farm.space_actual | prettyBytesIntl(1, true) }})</span></span>
          </div>
          <div class="col-md-3" v-if="farm.plots">
            Plots
          </div>
          <div class="col-md-9" v-if="farm.plots">
            <span v-if="loading">...</span>
            <span v-else>{{ new Intl.NumberFormat().format(farm.plots) }}</span>
          </div>
          <div class="col-md-3">
            Difficulty
          </div>
          <div class="col-md-9">
            <span v-if="loading">...</span>
            <span v-else>{{ new Intl.NumberFormat().format(farm.difficulty) }}</span>
          </div>
          <div class="col-md-3">
            Blocks Found
          </div>
          <div class="col-md-9">
            <span v-if="loading">...</span>
            <span v-else>{{ new Intl.NumberFormat().format(farm.blocks_found) }}</span>
          </div>
          <div class="col-md-3">
            Partial Success Rate
          </div>
          <div class="col-md-9">
            <span v-if="loading">...</span>
            <span v-else>{{ new Intl.NumberFormat().format(farm.partials_valid / (farm.partials_valid+farm.partials_invalid+farm.partials_stale) * 100) }} %</span>
          </div>
        </div>
        <h2 style="margin-top: 1.5em;">Points</h2>
        <div class="row">
          <div class="col-md-12">
            <vue-apex-charts
                type="line"
                height="350"
                width="100%"
                :options="chartOptionsComputed"
                :series="pointsChartData"
            />
          </div>
        </div>
        <h2 style="margin-top: 1.5em;" v-if="sharesChartData.length > 0">Shares [XCH]</h2>
        <div class="row" v-if="sharesChartData.length > 0">
          <div class="col-md-12">
            <vue-apex-charts
                type="line"
                height="350"
                width="100%"
                :options="chartOptionsComputed"
                :series="sharesChartData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "Farmer",
  components: {
    VueApexCharts
  },
  data() {
    return {
      launcher_id: "123",
      token: null,
      loading: true,
      farm: {
        nickname: null,
        points: 0,
        total_points: 0,
        share: 0.0,
        rank: 0,
        blocks_found: 0,
        space: 0,
        space_actual: null,
        total_payout: null,
        unpaid_balance: null,
        plots: null,
        partials_processing: 0,
        partials_valid: 0,
        partials_invalid: 0,
        partials_stale: 0,
        difficulty: 0,
      },
      granularity_points: 60 * 60,
      granularity_shares: 7 * 24 * 60 * 60,
      partials: [],
      shares: [],
      pointsChartData: [],
      sharesChartData: [],
      chartOptions: {
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false
          }
        },
        colors: ['#40c269', '#40c269', '#ffc400', '#f00'],
        stroke: {
          width: [2.5, 1.5, 2.5, 2.5],
          dashArray: [0, 2, 0, 0],
        }
      },
    }
  },
  methods: {
    setLauncherId() {
      let urlParams = new URLSearchParams(window.location.search);
      let launcher_id = urlParams.get('launcherid');
      this.launcher_id = launcher_id
    },
    login() {
      if (this.token) {
        return
      }
      const cookie_token = this.$cookies.get('refresh_token')

      if (cookie_token) {
        fetch('https://api.chiahub.io/v1/refresh', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie_token}`
          },
        })
            .then(response => response.json())
            .then(resp => {
              this.token = resp.token
              this.$cookies.set('refresh_token',
                  resp.refresh_token,
                  new Date(new Date().getTime() + 89 * 24 * 60 * 60 * 1000),
                  '/', '.chiahub.io', true)

              this.getData()
            })
            .catch(error => {
              console.log(error)
            })
        return
      }

      fetch('https://api.chiahub.io/v1/login?launcher_id=demo&authentication_token=5449147&signature=8d2a4a99526a3835069c35312af592977f58965325507faaddddea03eea92459a95902ef2cc061f14dee871e802f8442082d758d4fb15d8359e2ac63d595fa8f4e2d4c97dafb68bca2aa954521e889388bd256b8ce39057e82804ec191dbd5cc', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
          .then(response => response.json())
          .then(resp => {
            this.token = resp.token
            this.getData()
          })
          .catch(error => {
            console.log(error)
          })
    },
    fetchPartials(url) {
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        },
      })
          .then(response => response.json())
          .then(resp => {
            this.partials.push(...resp.data)
            this.renderPartials()
          })
          .catch(error => {
            console.log(error)
          })
    },
    fetchShares(url) {
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        },
      })
          .then(response => response.json())
          .then(resp => {
            if ('data' in resp) {
              this.shares.push(...resp.data)
              this.renderShares()
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    getData() {
      fetch(`https://api.chiahub.io/v1/farm/${this.launcher_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        },
      })
          .then(response => response.json())
          .then(resp => {
            console.log(resp)
            this.farm = {...resp}
            this.loading = false
          })
          .catch(error => {
            console.log(error)
          })
      {
        const now = new Date()
        now.setUTCMinutes(0)
        now.setUTCSeconds(0)
        now.setUTCMilliseconds(0)
        const end = Math.round(now.getTime() / 1000) - 60 * 60
        const start = Math.round(now.getTime() / 1000) - 48 * 60 * 60
        this.fetchPartials(`https://api.chiahub.io/v1/farm/${this.launcher_id}/stats/partials?from=${start}&end=${end}&limit=48`)
      }

      {
        const start = new Date()
        start.setUTCHours(0)
        start.setUTCMinutes(0)
        start.setUTCSeconds(0)
        start.setUTCMilliseconds(0)
        const end = new Date(start.getTime())

        start.setUTCFullYear(start.getUTCFullYear() - 1)
        let firstDayOfWeek = 1 //this.$moment.localeData().firstDayOfWeek();
        var diffDays = start.getDay() - firstDayOfWeek
        start.setDate(start.getDate() - diffDays)
        if (diffDays < 0) {
          start.setDate(start.getDate() - 7)
        }
        start.setDate(start.getDate() - 7)

        diffDays = end.getDay() - firstDayOfWeek
        end.setDate(end.getDate() - diffDays)
        if (diffDays < 0) {
          end.setDate(end.getDate() - 7)
        }

        const start_timestamp = Math.round(start.getTime() / 1000)
        const end_timestamp = Math.round(end.getTime() / 1000)
        this.fetchShares(`https://api.chiahub.io/v1/farm/${this.launcher_id}/stats/shares?from=${start_timestamp}&to=${end_timestamp}&limit=52&granularity=168&sort=desc`)
      }

    },
    renderPartials() {
      let avg
      if (this.partials.length > 0) {
        avg = Math.round(this.partials.map(v => v.successful).reduce((pv, cv) => pv + cv, 0) / this.partials.length)
      } else {
        avg = 0
      }
      this.pointsChartData.splice(0, this.pointsChartData.length)
      this.pointsChartData.push(
          {
            name: 'points',
            data: this.partials.map(v => [(v.timestamp) * 1000, v.successful ]),
          },
          {
            name: 'average',
            data: this.partials.map(v => [(v.timestamp) * 1000, avg ]),
          },
          {
            name: 'stale points',
            data: this.partials.map(v => [(v.timestamp) * 1000, v.stale ]),
          },
          {
            name: 'invalid points',
            data: this.partials.map(v => [(v.timestamp) * 1000, v.invalid ]),
          },
      )
    },
    renderShares() {
      this.sharesChartData.splice(0, this.sharesChartData.length)
      this.sharesChartData.push(
          {
            name: 'XCH',
            data: this.shares.map(v => [(v.timestamp) * 1000, Math.round(v.amount * 1000) / 1000 ]),
          },
      )
    },
  },
  computed: {
    chartOptionsComputed() {
//      const { filters } = this.$options

      const options = JSON.parse(JSON.stringify(this.chartOptions))
      options.tooltip = {}
      options.tooltip.x = {
        show: true,
        formatter(value) {
          return new Date(value)
        },
      }
      return options
    }
  },
  mounted() {
    this.setLauncherId()
    this.login()
  },
}
</script>

<style scoped>

</style>
