<template>
  <div>
    <!-- INTRO HEAD -->
    <div class="intro1">
      <div class="overlay"></div>
      <div class="container">
        <div class="row center-content">
          <div class="col-md-12 text-center">
            <h2>Get Crypto - the green, chia way</h2>
            <p class="space30 editContent">
              chiahub.io is a pool for an exiting new cryptocurrency called chia. Join
              us to get more out of your farming (mining) efforts.
            </p>
            <router-link to="/tutorial" class="btn btn-default btn-ico">Join now<i class="ti-blackboard"></i></router-link>
            <a href="#info" class="btn btn-default2 btn-ico page-scroll">Learn More<i class="ti-angle-right"></i></a>
          </div>
        </div>
      </div>
    </div>
    <!-- INTRO HEAD -->

    <!-- SERVICES -->
    <div class="service-content" id="services">
      <div class="container">
        <h2>For the impatient and experienced chia farmer (TL;DR)</h2>
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <div class="icon-box text-center">
						<span class="ico">
						<i class="fa icon-albums fa-stack-1x fa-inverse"></i>
						</span>
              <h4>Pool-URL</h4>
              <p>https://pool.chiahub.io</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="icon-box text-center">
						<span class="ico">
						<i class="fa icon-monitor fa-stack-1x fa-inverse"></i>
						</span>
              <h4>Farming Dashboard</h4>
              <p><a href="https://dashboard.chiahub.io">https://dashboard.chiahub.io</a></p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="icon-box text-center">
						<span class="ico">
						<i class="fa icon-box2 fa-stack-1x fa-inverse"></i>
						</span>
              <h4>Stats</h4>
              <p>
                Luck <i class="fa icon-info text-warning tooltip2">
                  <span class="tooltiptext">Pool-Luck of last 7 and 30 days and overall. Less than 100% means pool was lucky.</span>
                </i>:
                {{ Math.ceil(info.luck_7days * 100) }}%,
                {{ Math.ceil(info.luck_30days * 100) }}%,
                {{ Math.ceil(info.luck * 100) }}%<br/>
                {{ Math.round(info.poolspace / Math.pow(1024, 5)) }} PiB,
                {{ new Intl.NumberFormat().format(info.farmer_total) }} farmer, {{ new Intl.NumberFormat().format(info.earnings) }} XCH
              </p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="icon-box text-center">
						<span class="ico">
						<i class="fa icon-attention fa-stack-1x fa-inverse"></i>
						</span>
              <h4>Terms</h4>
              <p>
                Fee: {{ Math.ceil(info.fee * 100) }}%, PPLNS Window: 48h<br/>
                Minimum Payout: {{ new Intl.NumberFormat().format(info.payout_threshold) }} XCH<br/>
                Payment-Cycle: daily 17.00 UTC
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SERVICES -->

    <!-- INFO CONTENT -->
    <div class="info-content" id="info" ref="info">
      <div class="container">
        <div class="row center-content">
          <div class="col-md-5">
            <img :src="require('@/assets/images/close-up-of-open-hard-disk-drive-hdd-2022-01-28-06-33-11-utc.jpg')" class="img-responsive " alt="">
          </div>
          <div class="col-md-7">
            <h4>What is Chia?</h4>
            <p>
              Chia is a relatively new cryptocurrency that aims at being more eco-friendly
              than other popular cryptocurrencies. Instead of using large amounts of energy
              to secure the blockchain it uses available disk storage. Because chia has low
              requirements on disks you can use old, spare, cheap, refurbished, ... HDDs to
              participate. Like with other cryptocurrencies chia rewards you for your help
              in securing the blockchain by giving you a chance to "win" a block. Winning
              a block means you receive some chia / XCH (currently: 2 XCH per block win).</p>
          </div>
        </div>
      </div>
    </div>
    <!-- INFO CONTENT -->

    <!-- DUAL CONTENT -->
    <div class="dual-content" id="dual-info">
      <div class="container-fluid">
        <div class="row">
          <div class="dual-img"></div>
          <div class="col-md-8">
            <div class="dc-info">
            <h4>Why use a pool?</h4>
              <p>
              Chia produces around 4608 blocks per day. Your chances of winning a block is the amount
              of disk space you contribute to the chia network divided by the disk space of the whole
              network. If you use let's say
              <input type="number" v-model="hdds" style="width: 3em; padding: 0px; color: black; height: 1.75em; background: #30B573; border-style: solid; border-width: 0px; color: white; text-align: center"/>
              HDDs with
              <input type="number" v-model="tib_per_hdd" style="width: 3em; padding: 0px; color: black; height: 1.75em; background: #30B573; border-style: solid; border-width: 0px; color: white; text-align: center"/>
              TiB your chance of winning a block is
              </p>
              <p style="margin-left: 2em">
              {{ hdds * tib_per_hdd }} TiB / {{ info.netspace | prettyBytesIntl(1, true) }} =
              {{ new Intl.NumberFormat(locale, {minimumFractionDigits: 7}).format(hdds * tib_per_hdd * 1024 * 1024 * 1024 * 1024 / info.netspace * 100) }}%.
              </p>
              <p>
              In a pool many people (or farmers as we like to call us) combine their space (aka farm) to
              increase the chances of winning (much like a group of people playing the lottery together). In fact if you had
              been a pool member with us for the last
              <input type="number" v-model="days" style="width: 3em; padding: 0px; color: black; height: 1.75em; background: #30B573; border-style: solid; border-width: 0px; color: white; text-align: center"/>
              days these {{ hdds * tib_per_hdd }} TiB would have gotten you
              {{ new Intl.NumberFormat().format(info.mojos_per_point_7days * (days / 7) * expPoints / (10 ** 12)) }} XCH which
              are currently
              {{ new Intl.NumberFormat(locale, {style: 'currency', currency: 'USD'}).format(info.chia_usd * info.mojos_per_point_7days * (days / 7) * expPoints / (10 ** 12)) }}
              or {{ new Intl.NumberFormat(locale, {style: 'currency', currency: 'EUR'}).format(info.chia_eur * info.mojos_per_point_7days * (days / 7) * expPoints / (10 ** 12)) }} worth.
              Now granted this is not a lot, but with all new cryptos you may want to speculate that prices will only increase from here on.
              </p>
            </div>
          </div>
          <div class="col-md-4">
              <div class="dc-info">
                <ul class="space30" style="margin-top: 5em;">
                  <li><span class="fa fa-check list-icon" style=""></span><a href="#">Constant Winning</a></li>
                  <li><span class="fa fa-check list-icon" style=""></span><a href="#">Plannable Income</a></li>
                  <li><span class="fa fa-check list-icon" style=""></span><a href="#">Big Community</a></li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DUAL CONTENT -->

    <!-- FEATURES -->
    <section class="feature-content" id="features">
      <div class="container">
        <div class="row">
          <div class="col-md-12 content-head-lite text-center">
            <h2>Why chiahub.io?</h2>
            <p>As a chia farmer you got the choice among many pools. What makes us so special?</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 fc-inner">
            <div class="fc-thumb">
              <img :src="require('@/assets/images/man-working-with-analytics-at-the-office-2021-12-09-03-18-48-utc.jpg')" class="img-responsive" alt="">
            </div>
            <h4>User-friendly dashboard</h4>
            <p>As a chiahub.io farmer you get access to a powerful user dashboard enabling you to configure various
              alarms, set a friendly nickname and inform yourself about how your farm is doing.</p>
          </div>
          <div class="col-md-4 fc-inner">
            <div class="fc-thumb">
              <img :src="require('@/assets/images/stockbroker-in-shirt-is-working-in-a-monitoring-ro-2021-08-29-14-45-12-utc.jpg')" class="img-responsive" alt="">
            </div>
            <h4>Pro-active monitoring</h4>
            <p>It is important that your farm stays connected and continues to try and win a block. We continuously
              check if the farms connected to our pool function as expected and when there is a problem you will
              receive an email informing about the problem and potential fixes to it. </p>
          </div>
          <div class="col-md-4 fc-inner">
            <div class="fc-thumb">
              <img :src="require('@/assets/images/server-room-interior-in-datacenter-3d-render-2021-08-29-17-36-38-utc.jpg')" class="img-responsive" alt="">
            </div>
            <h4>Secure data-center</h4>
            <p>We run multiple chia nodes and pool server in a secure AWS data-center in central europe. You
              can rely on our expertise and infrastructure to make your farming experience as smooth as possible. </p>
          </div>
        </div>
        <div class="row" style="margin-top: 3em;">
          <div class="col-md-4 fc-inner">
            <div class="fc-thumb">
              <img :src="require('@/assets/images/tax-preparation-tax-time-tax-forms-nominated-2021-09-04-08-34-09-utc.jpg')" class="img-responsive" alt="">
            </div>
            <h4>Tax / Account Statements</h4>
            <p>From your dashboard you can download account statements that help you in declaring taxes.</p>
          </div>
          <div class="col-md-4 fc-inner">
            <div class="fc-thumb">
              <img :src="require('@/assets/images/cash-money-in-a-hand-2021-08-26-23-04-55-utc.jpg')" class="img-responsive" alt="">
            </div>
            <h4>Fast payouts</h4>
            <p>We pay out daily with an optional minimum payout value that you can set. Otherwise we pay all your
              shares daily at 17.00 UTC.</p>
          </div>
          <div class="col-md-4 fc-inner">
            <div class="fc-thumb">
              <img :src="require('@/assets/images/business-buildings-in-auckland-2021-08-26-16-22-22-utc.jpg')" class="img-responsive" alt="">
            </div>
            <h4>Registered company</h4>
            <p>chiahub.io is an officially registered EU based business and you will always find an imprint on our page.
              This gives you the peace of mind that the pool will not just disappear, we put our heart in it,
              and we put our name and face on it! </p>
          </div>
        </div>
      </div>
    </section>
    <!-- FEATURES -->

    <!-- TESTIMONIALS -->
    <div class="testimonials" id="testimonials">
      <div class="container">
        <div class="row">
          <div class="content-head space60 text-center">
            <h2>Statements from our Farmers</h2>
            <p>Hear what our farmers say about chiahub.io</p>
          </div>
        </div>
        <div class="row">
          <div class="quote-slider">
            <div class="quote-item">
              <div class="quote-info">
                <p class="quote">I think I landed in a great professional outfit I can trust, which is priceless.</p>
                <div class="author">
                  <img class="author-avatar" src="@/assets/images/quote/Discord-Logo-Color.svg" height="48" width="48" alt="" />
                  <div class="author-info">
                    <div class="name">Badger</div>
                    <div class="company">@discord</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="quote-item">
              <div class="quote-info">
                <p class="quote">Thanks for all your hard work guys.</p>
                <div class="author">
                  <img class="author-avatar" src="@/assets/images/quote/Discord-Logo-Color.svg" height="48" width="48" alt="" />
                  <div class="author-info">
                    <div class="name">nidirom</div>
                    <div class="company">@discord</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="quote-item">
              <div class="quote-info">
                <p class="quote">this pool has been pretty solid! thank you! </p>
                <div class="author">
                  <img class="author-avatar" src="@/assets/images/quote/Discord-Logo-Color.svg" height="48" width="48" alt="" />
                  <div class="author-info">
                    <div class="name">h.e.</div>
                    <div class="company">@discord</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="quote-item">
              <div class="quote-info">
                <p class="quote">Also, thanks for maintaining such a smooth running pool and looking forward for what's to come.</p>
                <div class="author">
                  <img class="author-avatar" src="@/assets/images/quote/Discord-Logo-Color.svg" height="48" width="48" alt="" />
                  <div class="author-info">
                    <div class="name">tailwind_mrs</div>
                    <div class="company">@discord</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="quote-item">
              <div class="quote-info">
                <p class="quote">I like it very much, thanks! 😃😃👍🏻🌱 Great job guys!</p>
                <div class="author">
                  <img class="author-avatar" src="@/assets/images/quote/Discord-Logo-Color.svg" height="48" width="48" alt="" />
                  <div class="author-info">
                    <div class="name">cryptosascha_ch</div>
                    <div class="company">@discord</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="quote-item">
              <div class="quote-info">
                <p class="quote">Thanks for all the replies guys. Love this pool and the his community</p>
                <div class="author">
                  <img class="author-avatar" src="@/assets/images/quote/Discord-Logo-Color.svg" height="48" width="48" alt="" />
                  <div class="author-info">
                    <div class="name">Jose</div>
                    <div class="company">@discord</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TESTIMONIALS -->

    <!-- INFO CONTENT -->
    <div class="info-content">
      <div class="container">
        <div class="row center-content">
          <div class="col-md-7 col-md-push-5">
            <img :src="require('@/assets/images/plotting_cloud_illustration.png')" class="img-responsive " alt="">
          </div>

          <div class="col-md-5 col-md-pull-7">
            <h4>Buy plots from Plotting Cloud</h4>
            <p>Get an extra 20% discount from our plotting partner at Plotting Cloud.
              When you buy more than 100 plots you get additional 5 plots for free!</p>
            <a href="https://plotting.cloud/order?promo=chiahub" class="btn btn-default btn-ico">Buy Plots <i class="ti-shopping-cart"></i></a>
          </div>
        </div>
      </div>
    </div>
    <!-- INFO CONTENT -->

    <!-- CALL TO ACTION -->
    <div class="cta-content">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-8 col-md-offset-2">
            <h3>How to start?</h3>
            <p class="space30">Being new to chia or cryptocurrencies in general can be overwhelming. We created a simple step by step <router-link style="color: #11a4a1" to="/tutorial">tutorial</router-link> and a short video to guide you through getting started:</p>
            <iframe src="https://www.youtube.com/embed/gFgJMDDO_nA" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- CALL TO ACTION -->

  </div>
</template>

<script>

export default {
  name: "StartPage",
  components: {
  },
  data() {
    return {
      locale: (new Intl.NumberFormat()).resolvedOptions().locale,
      days: 7,
      hdds: 2,
      tib_per_hdd: 5,
      info: {},
    }
  },
  computed: {
    expPoints() {
      return this.hdds * this.tib_per_hdd * 1024 * 1024 * 1024 * 1024 * ((1.088 / (10 ** 15)) * 48 * 60 * 60)
    },
  },
  created() {
    this.$http.get('https://api.chiahub.io/v1/pool/info')
      .then(resp => {
        this.info = resp.data
      })
  },
  methods: {
    scrollTo(refName) {
      window.scrollTo(0, this.$refs[refName].offsetTop)
    }
  }
}
</script>

<style scoped>
>>> {
  --vs-border-width: 0px;
  --vs-border-radius: 0px;
}
.v-select {
  display: inline-block;
}
.tooltip2 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip2 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
