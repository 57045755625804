<template>
  <div id="app">
    <Menu></Menu>
    <router-view></router-view>
    <trailer />
  </div>
</template>

<script>
import Trailer from "@/components/Trailer";
import Menu from "@/components/Menu";
export default {
  name: 'App',
  components: {
    Trailer,
    Menu,
  }
}
</script>

<style>
</style>
