<template>
  <div>
    <!-- INTRO HEAD -->
    <div class="intro2" style="background: url(/images/bg/business-team-2021-08-26-22-39-54-utc.jpg) no-repeat center; background-size: cover;">
      <div class="overlay"></div>
      <div class="container">
        <div class="row center-content">
          <div class="col-md-12 text-center" style="margin-top: 5em; margin-bottom: 3em;">
            <h2>About Us</h2>
            <p>The founders behind bloxxum GmbH consist of four tech enthusiasts which throughout their careers
              always believed in the value of exploring new technology.
              With the development of blockchain they immediately saw the benefit, it can bring to everyone.
              Each of the founders brings on board a very broad and diverse set of skills that if combined together,
              enables them to make vision become reality.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- INTRO HEAD -->
    <!-- INFO CONTENT -->
    <div class="info-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
          <h2>Our Team</h2>
          </div>
        </div>
        <div class="row" style="margin-top: 1.5em;">
          <div class="col-lg-4">
            <img :src="require('@/assets/images/swen.jpg')" class="img-responsive" alt="Swen Brüseke" />
          </div>
          <div class="col-lg-8 lead">
            Swen Brüseke is a serial Tech-Entrepreneur and Evangelist. He is founding and successfully operating
            Tech- and Internet focused companies for 15+ years. He is deeply invested in state-of-the-art Technology
            and pushing the boundaries of the possible. If he ever finds the time, he would love to build a classic
            gaming handheld.
          </div>
        </div>
        <div class="row" style="margin-top: 1.5em;">
          <div class="col-lg-4">
            <img :src="require('@/assets/images/yan.jpg')" class="img-responsive" alt="Yan Hackl-Feldbusch" />
          </div>
          <div class="col-lg-8 lead">
            Yan Hackl-Feldbusch is a tech enthusiast knowing all sorts of programming languages like java, c, python, …
            Having worked 10+ years as a professional developer in security sensitive environments for banks and
            insurances Yan is responsible for making our pool secure, reliable and fast. In his spare time he studies
            AI techniques like machine learning for fun and builds automated IOT plant watering devices.
          </div>
        </div>
        <div class="row" style="margin-top: 1.5em;">
          <div class="col-lg-4">
            <img :src="require('@/assets/images/sebastian.jpg')" class="img-responsive" alt="Sebastian Beyl" />
          </div>
          <div class="col-lg-8 lead">
            Sebastian Beyl is a programmer since 20+ years. His skills in web programming languages and scalable
            database models are perfect for growing up complex projects very stable and fast.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

</style>
