<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <h1 class="footer-logo"><router-link to="/">chiahub.io</router-link></h1>
          <p>chiahub.io is a service provided by bloxxum GmbH and is operated full and on its own. Chia Network™ and the Chia Blockchain™ are trademarks or registered trademarks of Chia Network, Inc. in the United States and worldwide, a Delaware corporation, used under license. bloxxum GmbH is not associated to Chia Network, Inc. and every licensed trademark displayed on this website belongs to their respective owners.</p>
          <ul class="footer-social">
            <li><a href="https://twitter.com/chiahubio" target="_blank"><i class="fab fa-twitter"></i></a></li>
            <li><a href="https://www.facebook.com/Chiahubio-110866081255457/" target="_blank"><i class="fab fa-facebook"></i></a></li>
            <li><a href="https://discord.gg/Rb5hAMtB2Z" target="_blank"><i class="fab fa-discord"></i></a></li>
            <li><a href="https://www.youtube.com/channel/UCbDtFq3w6tCE21GQbgjqbQg" target="_blank"><i class="fab fa-youtube"></i></a></li>
          </ul>
        </div>
        <div class="col-md-4">
          <h4>Related Links</h4>
          <div class="row">
            <div class="col-md-6">
              <ul class="footer-list">
                <li><router-link to="/about">About Us</router-link></li>
                <li><router-link to="/imprint">Imprint</router-link></li>
                <li><router-link to="/terms">Terms & Conditions</router-link></li>
                <li><router-link to="/contact">Contact Us</router-link></li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="footer-list">
                <li><router-link to="/fees">Pool Fees</router-link></li>
                <li><a href="https://api-docs.chiahub.io" target="_blank">API</a></li>
                <li><a href="https://kb.chiahub.io/portal/en/home" target="_blank">Support</a></li>
                <li><a href="https://kb.chiahub.io/portal/en/kb/articles/frequently-asked-questions" target="_blank">FAQ</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h4>Imprint</h4>
          <p>bloxxum GmbH<br/>Kleyerstrasse 75-87<br/>60326 Frankfurt am Main<br/>GERMANY</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Trailer"
}
</script>

<style scoped>

</style>
