<template>
  <div>
    <!-- INTRO HEAD -->
    <div class="intro2" style="background: url(/images/bg/2.jpg) no-repeat center; background-size: cover;">
      <div class="overlay"></div>
      <div class="container">
        <div class="row center-content">
          <div class="col-md-12 text-center" style="margin-top: 5em; margin-bottom: 3em;">
            <h2>Contact&nbsp;Us</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- INTRO HEAD -->

    <!-- INFO CONTENT -->
    <div class="info-content">
      <div class="container">
        <div class="row center-content">
          <div>
            <iframe id="zsfeedbackFrame" width="890" height="570" name="zsfeedbackFrame" scrolling="no" allowtransparency="false" frameborder="0" border="0" src="https://desk.zoho.eu/support/fbw?formType=AdvancedWebForm&fbwId=edbsn1c548794eb09302ebf94b07c138ad41368092b78cbecc728067625451f0b46f4&xnQsjsdp=edbsn2adac4d1da11072ddd3d77097a656082&mode=showNewWidget&displayType=iframe"></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- INFO CONTENT -->
  </div>
</template>

<script>
export default {
  name: "Contact",
}
</script>

<style scoped>

</style>
