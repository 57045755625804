<template>
<div>
  <!-- INTRO HEAD -->
  <div class="intro2" style="background: url(/images/bg/boring-paperwork-2021-08-31-15-52-41-utc.jpg) no-repeat center; background-size: cover;">
    <div class="overlay"></div>
    <div class="container">
      <div class="row center-content">
        <div class="col-md-12 text-center" style="margin-top: 5em; margin-bottom: 3em;">
          <h2>Terms &amp; Conditions</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- INTRO HEAD -->
  <!-- INFO CONTENT -->
  <div class="info-content">
    <div class="container">
      <div class="row center-content">
          <p class="lead">
            Welcome to chiahub.io! These Terms of Service (this "Terms") contains the terms and conditions that govern your access to and use of the Services (as defined below) and is an agreement between chiahub.io a Project of Cryptico GmbH (the "Company" "we," "us," or "our") and you or the entity you represent ("you" or "your"). lf you join our pool by creating a Chia smart contract for pooling ("PlotNFT") for Chiahub.io, view or use any other chiahub.io services, it will be deemed that you have read and agreed to the Term s. lf you do not accept the limitation of the Terms, please do not view or use the Services. You represent to us that you are lawfully able to enter into contracts (e.g., you are not a minor).
          </p>
      </div>
      <div class="row">
        <h2>USE OF THE SERVICES</h2>
        <h3>1.1 General</h3>
        <p class="lead">
          You may access and use the Services in accordance with these Terms. You will comply with the Terms and all laws, rules and regulations applicable to your use of the Services. The Services allow for pooling of Chia plots in order to normalize the distribution of Chia rewards. You are solely responsible for the rewards and farming.
        </p>
        <h3>1.2 Your Account</h3>
        <p class="lead">
          To access the Services, you must create a PlotNFT for chiahub.io using the official Chia pooling protocol. To manage your account you must create a login token ("Login Token") associated with your PlotNFT via the official Chia app.
        </p>
        <h2>MODIFICATION</h2>
          <h3>2.1 New Applications and Services</h3>
          <p class="lead">
            We may: (i) make new applications, tools, features or functionality available from time to time through the Services; and (ii) add new services from time to time, the use of which may be contingent upon your agreement to additional terms.
          </p>
          <h3>2.2 Modifications to the Services and Agreement</h3>
          <p class="lead">
            We may make updates to Services from time to time. lf the Company makes a material change to the Services, it will inform you, provided that you have subscribed with the Company to be informed of such change. The Company may make changes to this Agreement, and any linked documents, from time to time. Unless otherwise noted, material changes to the Terms will become effective five (5) days after they are posted, except if the changes apply to new functionality in which case they will be effective immediately. The Company shall take reasonable efforts to provide at least five (5) days' advance notice for materially adverse changes to any Services by either: (i) posting a notice in the news section on the Company website; (ii) mobile apps; or (iii) posting a notice to the Support webpage. The Company will post any modification to this Agreement to its website.
          </p>
        <h2>PAYMENT TERMS, FEES, AND TAXES</h2>
          <h3>3.1 Pool Fees</h3>
          <p class="lead">
            You shall pay and owe the Company certain pool fees for use of the Services as described in these Terms ("Pool Fees"). The Company shall calculate and automatically deduct the Pool Fees from every blockchain reward you actively participated in by submitting at least one valid proof-of-space-and-time to the Service in the period between the preceding and current rewards. The current fee details, payout schedule, and schedule of fees can be found on the Company website at https://www.chiahub.io/fees. Any changes to fee details, payout schedule, and/or schedule of fees will be notified to you in the manner as described under these terms.
          </p>
          <h3>3.2 Blockchain Transaction Fees</h3>
          <p class="lead">
            The delivery and receipt of any of your cryptocurrencies is subject to network or transaction fees charged by the blockchain associated with the Chia Blockchain ("Blockchain Transaction Fees"). Blockchain Transaction Fees are paid to emit, record, verify, and process a transaction on the blockchain and not retained by the Company. The Company does not have any influence on the Chia cryptocurrency blockchain and farming process at all. The Company only provides an interface to the Chia blockchain. Any questions about the Chia cryptocurrency must be directed to Chia Network ("The Chia Blockchain Maintainers"), instead.
          </p>
          <h3>3.3 Third-Party Fees</h3>
          <p class="lead">
            Certain digital wallets, wallet addresses, tools, and third-party software and devices ("Third­ Party Wallets") used by you may also charge User a fee, including a per transaction or transfer fee. User is responsible for being aware of and satisfying any such fee. You should note that any such fees may significantly reduce User's farming rewards and therefore User is responsible for managing the selection, use, and rate and frequency of their receipt of farming rewards to any such Third-Party Wallets.
          </p>
          <h3>3.4 Taxes</h3>
          <p class="lead">
            Each party will be responsible, as required under applicable law, for identifying and paying all taxes and other governmental fees and charges (and any penalties, interest, and other additions thereto) that are imposed on that party upon or with respect to the transactions and payments under these terms. All fees payable by you are exclusive of taxes, except where applicable law requires otherwise. We may charge and you will pay applicable taxes that we are legally obligated or authorized to collect from you. You will provide such information to us as reasonably required to determine whether we are obligated to collect taxes from you. We will not collect, and you will not pay, any tax for which you furnish us a properly completed exemption certificate or a direct payment permit certificate for which we may claim an available exemption from such tax. All payments made by you to us under these terms will be made free and clear of any deduction or withholding, as may be required by law. lf any such deduction or withholding (including but not limited to cross-border withholding taxes) is required on any payment, you will pay such additional amounts as are necessary so that the net amount received by us is equal to the amount then due and payable under these Terms. We will provide you with such tax forms as are reasonably requested in order to reduce or eliminate the amount of any withholding or deduction for taxes in respect of payments made under these Term s. You will be responsible for your own tax reporting.
          </p>
        <h2>USER OBLIGATIONS</h2>
          <h3>4.1 Digital Wallet and Private Keys</h3>
          <p class="lead">
            You represent and warrant that you are familiar with and accept the risks associated with digital wallets and private keys, including the risks described in this Section 4.1 or Section 5. Your digital wallet or vault may require a private key, mnemonic, or a combination of private keys and mnemonics (collectively, "Keys") for access. Accordingly, loss of requisite Keys associated with your digital wallet or vault storing cryptocurrencies will result in loss of such cryptocurrencies, access to your cryptocurrencies balance and/or any initial balances in blockchains. Moreover, any third-party that gains access to such private key(s), including by gaining access to login credentials of a hosted wallet or vault service you use, may be able to misappropriate your cryptocurrencies. The Company is not responsible for any such lasses.
          </p>
          <h3>4.2 Log-In Credentials</h3>
          <p class="lead">
            You represent and warrant that you are responsible for the preservation of confidentiality of your Login Token and PlotNFT. Use of any Login Token and/or PlotNFT with the Services is for your use only and you are strictly prohibited from selling, transferring, or sublicensing them to any other entity or person.
          </p>
          <h3>4.3 Restricted Territories</h3>
          <p class="lead">
            You represent that you are entering into these Terms to access the Services, then you are not a resident, national, or agent of Burundi, Cuba, Crimea region, Iran, Iraq, Lebanon, Libya, North Korea, Somalia, Sudan, Syria, Venezuela, Yemen or any other country to which the United States embargoes goods or imposes similar sanctions (collectively, "Restricted Territories").
          </p>
        <h2>RISKS</h2>
          <h3>5.1 Blockchain Network Risk</h3>
          <p class="lead">
            You represent and warrant that you accept the risks of the Chia blockchain protocol and network, including instability, congestion, high transaction costs, network latency, information security, regulatory risk, and technological and operational error. You understand that these risks may result in delay or failure to process transact ions, failure to deliver farming rewards, and high Blockchain Transaction Fees. You represent that you understand and agree that the Company is not responsible for any diminished Services, related features, or capabilities resulting from blockchain network risk. In the event of a material increase or decrease to Blockchain Transaction Fees or operational degradation, congestion, failure or disruption otherwise of the blockchain network used by you, the Company may, at its sole discretion and upon notice to you, increase or decrease the threshold to deliver your cryptocurrencies.
          </p>
          <h3>5.2 Blockchain Modification Risk</h3>
          <p class="lead">
            You represent and warrant that you are familiar with and accept the risks associated with blockchain development and code changes, including the risks described in this Section 5.2. Blockchain technologies are still under development and may undergo significant changes over time. Blockchain developers may make changes to features and specifications of the algorithm selected by you. Such changes may include or result in the elimination of support for and/or the efficient use of hardware used by you or the Company. In addition, blockchain developers may also determine to modify the cryptographic verification process such that the blockchains can no longer be verified through proof-of-space-and-time and instead adopt proof-of-stake or other methodologies. Proof of Space and Time Replacement Risk.<br>
            <br>
            In addition to blockchain modification risk, blockchain developers may also determine to modify the cryptographic verification process such that the blockchains can no longer be verified through proof- of- space - and - time and instead adopt proof-of-stake or other methodologies. User accepts and acknowledges that in circumstances where the protocol of a given blockchain used by you have been modified to use other methodologies, you accept such risk and shall allocate User 's form space to other available blockchains and farming processes that use proof-of-space methodologies using the given algorithm for this Agreement. Certain blockchain algorithms at this time have no alternative blockchain applications that support proof-of-space farming and any such switch from would result in no applicable use of your form space should such a protocol switch occur.
          </p>
          <h3>5.3 Trade Compliance</h3>
          <p class="lead">
            In connection with this Agreement, you agree that you will comply with all applicable import, re-import, sanctions, anti-boycott, export, and re-export control laws and regulations, including all such laws and regulations that apply to European Union and U.S. companies, such as the Export Administration Regulations, the International Traffic in Arms Regulations, and economic sanctions programs implemented by the Office of Foreign Assets Control and the European Union's Common Foreign and Security Policy ("CFSP") (collectively, "Trade Sanctions Laws"). You represent and warrant that you and your financial institutions, or any party that owns or controls you or your financial institutions, are not subject to sanctions or otherwise designated on any list of prohibited or restricted parties, including but not limited to the lists maintained by the United Nations Security Council, the European Union or its Member States, the U.S. Government, or other applicable government authority.
          </p>
        <h2>TEMPORARY SUSPENSION</h2>
          <h3>6.1 General</h3>
          <p class="lead">
            The Company may suspend your right to access or use any portion or all of the Services immediately upon notice to you if: (i) the Company determines your use of the Services poses a security risk to the Services or any third party, could adversely impact the Company's systems, the Services or any other chiahub.io user, could subject the Company, its affiliates, or any third party to liability, or could be fraudulent; (ii) you are in breach of this Agreement; or (iii) you have ceased to operate in the ordinary course, made an assignment for the benefit of creditors or similar disposition assets, or become the subject of any bankruptcy, reorganization, liquidation, dissolution or similar proceeding.
          </p>
          <h3>6.2 Effect of Suspension</h3>
          <p class="lead">
            lf the Company suspends your right to access or use any portion or all the Services, you may remain responsible for all fees and charges you incur during the period of suspension; and you will not be entitled to any cryptocurrency farming rewards that may have occurred during the period your use of the Services was temporarily suspended.
          </p>
        <h2>TERM AND TERMINATION</h2>
          <h3>7.1 Agreement Term</h3>
          <p class="lead">
            The term of this Agreement will commence on the Effective Date and will remain in effect until the date of a Termination for Breach, the conditions of which are set forth in Section 7.2 below.
          </p>
          <h3>7.2 Termination for Breach</h3>
          <p class="lead">
            Either party may terminate this Agreement for breach if the other party is in material breach of the Agreement and fails to cure that breach within thirty days after receipt of written notice. In addition, in the event that the Company ceases its business operations or becomes subject to insolvency proceedings and the proceedings are not dismissed within ninety days, the Company may terminate the Service.
          </p>
          <h3>7.3 Effect of Termination</h3>
          <p class="lead">
            Upon the Termination Date, all of your rights under this Agreement immediately terminate and you shall remain responsible for all Service Fees incurred through the termination date provided by the Company.
          </p>
        <h2>ACCOUNT INACTIVITY</h2>
          <p class="lead">
            If you do not actively participate in pooling by submitting at least one valid proof-of-space­ and-time to the Service for a consecutive period of 6 months, the Company reserves the right to reset your account balances and deactivate your Account. Subject to Sections 6 and 7, and provided you comply with all other terms and conditions of this Agreement, you may rejoin the Service at any time with reset account balances.
          </p>
        <h2>CONFIDENTIAL INFORMATION</h2>
          <p class="lead">
            You may not disclose Confidential Information, except to agents or professional advisors who need to know it and who have agreed in writing (or in the case of professional advisors are otherwise bound) to keep it confidential. You shall ensure that those persons and entities use the received Confidential Information only to exercise rights and fulfill obligations under this Agreement, while using reasonable care to maintain the confidentiality of such information.
          </p>
        <h2>PUBLICITY</h2>
          <p class="lead">
            You are permitted to state publicly that you are a User of the Service, consistent with the Trademark Guidelines. lf you want to display chiahub.io a project of Cryptico GmbH trademarks or trade names in connection with its use of the Services, you must obtain written permission from the Company through the process specified in the Trademark Guidelines. You shall not issue any press release or make any other public communication with respect to this Agreement or your use of the Services.
          </p>
        <h2>DISCLAIMER</h2>
          <p class="lead">
            EXCEPT AS EXPRESSLY PROVIDED FOR IN THIS AGREEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY, ITS AFFILIATES, AND ITS SUPPLIERS DO NOT MAKE ANY OTHER WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE AND NON-INFRINGEMENT. THE COMPANY, ITS AFFILIATES, AND ITS SUPPLIERS ARE NOT RESPONSIBLE OR LIABLE FOR THE DELETION, FAILURE TO STORE, OR ANY LOSS OF ANY USER DATA, INCLUDING BLOCKCHAIN DATA AND CRYPTOCURRENCY REWARDS DERIVED, MAINTAINED, OR TRANSMITTED THROUGH USE OF THE SERVICES. YOU ARE SOLELY RESPONSIBLE FOR SECURING ITS USER DATA AND CRYPTOCURRENCY REWARDS. NEITHER THE COMPANY, ITS AFFILIATES, NOR ITS SUPPLIERS, WARRANTS THAT THE OPERATION OF THE SOFTWARE OR THE SERVICES WILL BE ERROR-FREE OR UNINTERRUPTED. THE COMPANY, ITS AFFILIATES, AND ITS SUPPLIERS ARE NOT RESPONSIBLE OR LIABLE FOR ANY LOSSES OR OPPORTUNITY COSTS RESULTING FROM BLOCKCHAIN NETWORK AND PROTOCOL OR THIRD-PARTY SOFTWARE ISSUES, WHICH MAY IN TURN RESULT IN THE INABILITY TO PROCESS TRANSACTION ON THE BLOCKCHAIN AT ALL OR WITHOUT INCURRING SUBSTANTIAL FEES.
          </p>
        <h2>LIMITATION OF LIABILITY</h2>
          <h3>12.1 Limitation of indirect Liability</h3>
          <p class="lead">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY, YOU, AND THE COMPANY'S SUPPLIERS, SHALL NOT BE LIABLE UNDER THIS AGREEMENT FOR LOST REVENUES, OPPORTUNITY COSTS, OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF THE COMPANY KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF DIRECT DAMAGES DO NOT SATISFY A REMEDY. THIS LIMITATION OF LIABILITY DOES NOT APPLY TO VIOLATIONS OF THE COMPANY'S INTELLECTUAL PROPERTY RIGHTS, INDEMNIFICATION OBLIGATIONS, OR YOUR PAYMENT OBLIGATIONS.
          </p>
          <h3>12.2 Limitation of Amount of Liability</h3>
          <p class="lead">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER THE COMPANY NOR THE COMPANY'S SUPPLIERS, MAY BE HELD LIABLE UNDER THIS AGREEMENT FOR MORE THAN THE POOL FEE AMOUNTS WE RETAIN DIRECTLY IN CONNECTION WITH YOUR USE OF THE SERVICES.
          </p>
          <h3>12.3 Disruptions; Downtime and Wear and Tear</h3>
          <p class="lead">
            Although the Company makes a reasonable effort to protect the Service from intrusion and cyberattacks, the Company will not be held liable for interruptions of the Services resulting in but not limited to lass of access to the website, pooling delays or disruptions, transactions, and unpaid rewards. In addition, although the Company shall make reasonable effort at sustaining acceptable Service uptime, the Company will not be held liable nor compensate for interruptions of Service, nor does the Company guarantee the uptime of the Service.<br>
            <br>
            Further, as farming and plotting may exert an intense load on your hardware, the Company shall not be liable for any damages to your hardware that may occur during the farming and plotting process.
          </p>
        <h2>INDEMNIFICATION</h2>
          <p class="lead">
            Unless prohibited by applicable law, you will defend and indemnify the Company and its Affiliates against any settlement amounts approved by you and damages and costs finally awarded against you and its affiliates by a court of competent jurisdiction in any formal legal proceeding filed by an unaffiliated third party before a court or government tribunal (including any appellate proceeding) to the extent arising from your use of the Services.
          </p>
        <h2>GENERAL PROVISIONS</h2>
          <h3>Assignment</h3>
          <p class="lead">
            You will not assign or otherwise transfer this Agreement or any of your rights and obligations under this Agreement, without the prior written consent of the Company, which may be unreasonably withheld. Any assignment or transfer in violation of this Section 14.1 will be void. In addition, you shall be the beneficial owner of any cryptocurrencies generated and/or received as a result of your use of the Services under this Agreement. The Company may assign this Agreement without your consent (i) in connection with a merger, acquisition or sale of all or substantially all of our assets, or (ii) to any Affiliate or as part of a corporate reorganization; and effective upon such assignment, the assignee is deemed substituted for the Company as a party to this Agreement and the Company is fully released from all of its obligations and duties to perform under this Agreement. Subject to the foregoing, this Agreement will be binding upon, and inure to the benefit of the parties and their respective permitted successors and assigns. You may not merge this Agreement with any other agreements with the Company it may be party to.
          </p>
          <h3>14.2 Change of Control</h3>
          <p class="lead">
            If you experience a change of Control (for example, through a stock purchase or sale, merger, or other form of corporate transaction): (i) you will give written notice to the Company within thirty days after the change of Control; and (ii) the Company may immediately terminate this Agreement any time between the change of Control and thirty days after it receives that written notice.
          </p>
          <h3>14.3 Disputes</h3>
          <p class="lead">
            Disputes will be resolved by binding and applicabale law, arbitration should be fe thirst attempt to resolve any dispute. Only when arbitration fails or is not possible, court proceeding shall be pursued. The place of governing law and court shall be at the place of the companies seat of business.<br>
            <br>
            Payment of filing, administration and arbitrator fees will be governed by the local law. We will reimburse those fees for claims totaling less than €100 unless the arbitrator determines the claims are frivolous. We and you agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. lf for any reason a claim proceeds in court rather than in arbitration we and you waive any right to a jury trial. Notwithstanding the foregoing we and you both agree that we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.
          </p>
          <h3>14.4 Entire Agreement</h3>
          <p class="lead">
            This Agreement sets out all terms agreed between the parties and supersedes all other agreements between the parties relating to its subject matter. In entering into this Agreement, neither party has relied on, and neither party will have any right or remedy based on, any statement, representation or warranty (whether made negligently or innocently), except those expressly set out in this Agreement. The terms located at a URL referenced in this Agreement and the Documentation are incorporated by reference into the Agreement. After the Effective Date, the Company may provide an updated URL in place of any URL in this Agreement.
          </p>
          <h3>14.5 Force Majeure</h3>
          <p class="lead">
            The Company and its affiliates will not be liable for any failure or delay in performance of obligation under this Agreement where the failures or delay results from any cause beyond our reasonable control, including, but not limited to, acts of God, labor disputes or other industrial disturbances, electrical or power outages, utilities or other telecommunications failures, earthquake, storms or other elements of nature, blockages, embargoes, riots, acts or orders of government, acts of terrorism, or war. Force Majeure events include, but are not limited to, upgrades to the validation rules of a given blockchain (e.g., a "hard fork" or "soft fork") and disruption of the Service due to outages.
          </p>
          <h3>14.6 Governing Law and Venue</h3>
          <p class="lead">
            All claims arising out of or relating to this Agreement or the Services will be governed by the laws of Austria or superseeding EU laws. The 1980 United Nations Convention on Contracts for the International Sole of Goods does not govern this Agreement.
          </p>
          <h3>14.7 Language</h3>
          <p class="lead">
            All communications and notices made or given pursuant to this Agreement must be in the English language. lf we provide a translation of the English language version of this Agreement, the English language version of the Agreement will control if there is any conflict.
          </p>
          <h3>14.8 No Agency</h3>
          <p class="lead">
            This Agreement does not create any agency, partnership or joint venture between the Company and you.
          </p>
          <h3>14.9 Notices to User</h3>
          <p class="lead">
            The Company may provide any notice to you under this Agreement by: (i) posting a notice on the chiahub.io website; or (ii) the chiahub.io apps. Notices we provide by posting on the chiahub.io website or apps will be effective upon posting.
          </p>
          <h3>14.10 Notices to the Company</h3>
          <p class="lead">
            To give us notice under this Agreement, you must contact the Company by facsimile transmission or personal delivery, overnight courier or registered or certified mail to the mailing address listed on the chiahub.io website and/or app or to chiahub.io a project of Cryptico GmbH, Schubertring 6, 1010 in Vienna. We may update the address for notices to us by posting a notice on the chiahub.io website. Notices provided by personal delivery will be effective immediately. Notices provided by overnight courier will be effective one business day after they are sent. Notices provided registered or certified mail will be effective five business days after they are sent.
          </p>
          <h3>14.11 Severability</h3>
          <p class="lead">
            If any portion of this Agreement is held to be invalid or unenforceable, the remaining portions of this Agreement will remain in full force and effect. Any invalid or unenforceable portions will be interpreted to effect and intent of the original portion. lf such construction is not possible, the invalid or unenforceable portion will be severed from this Agreement but the rest of the Agreement will remain in full force and effect.
          </p>
      </div>
    </div>
  </div>
  <!-- INFO CONTENT -->

</div>
</template>

<script>
export default {
  name: "Terms"
}
</script>

<style scoped>

</style>
