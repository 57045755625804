<template>
  <div>
    <!-- INTRO HEAD -->
    <div class="intro2" style="background: url(/images/bg/money-2021-08-30-14-25-56-utc.jpg) no-repeat center; background-size: cover;">
      <div class="overlay"></div>
      <div class="container">
        <div class="row center-content">
          <div class="col-md-12 text-center" style="margin-top: 5em; margin-bottom: 3em;">
            <h2>Fees &amp; Payout</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- INTRO HEAD -->
    <!-- INFO CONTENT -->
    <div class="info-content">
      <div class="container">
        <div class="row">
          <h2>Fee</h2>
            <p class="lead">chiahub.io’s pool fee is 1%!</p>
            <p class="lead">Fees will be collected before crediting your “Unpaid Balance”. For example, if your share for a block reward is 0.01 XCH and your schedule of fees is 1% after applying discounts, then your “Unpaid Balance” will be credited 0.0099 XCH and the pool collects 0.0001 XCH.
            </p>
          <h2>How does the pool distribute block rewards</h2>
            <p class="lead">chiahub.io’s pool uses PPLNS (Pay Per Last N Shares) to distribute block rewards. This means when a block has been won, we look at the last 48 hours (PPLNS window) and calculate all valid partials (shares) submitted by all users to the pool and distribute the block reward value based on your share within this PPLNS window, taking into account the number of partials and the difficulty of each valid partial. This share is then credited towards your “unpaid balance”.
            </p>
            <p class="lead">Only partials that are valid as defined by the official pooling protocol are counted and you need to be a farmer with a plotnft pointing to our pool at the time of the block win to be eligible to have your submitted partials to be counted towards the distribution of rewards.
            </p>
            <p class="lead">If a farmer joins the pool with unclaimed coins connected to his singleton, chiahub.io will claim those coins and distribute them after the time mentioned as PPLNS window to all farmers of the pool using PPLNS points.
            </p>
          <h2>Payout</h2>
            <p class="lead">Anyone above the minimum “Unpaid Balance” will be paid out. We are going to do 1 payout per day and our payout window is between 5pm and 6pm UTC! Your progress towards the minimum payout balance and your payout history can be found on your account page. chiahub.io’s pool will cover all transaction fees associated with the payout. Minimum “Unpaid Balances” for payouts are: 0.00 XCH
            </p>
            <p class="lead">All values regarding PPLNS, Fees, and Payouts are subject to periodic review and may be changed. Any changes will be posted on some or all of these sources: <a href="https://www.chiahub.io" target="_blank">www.chiahub.io</a>, chiahub.io Newsletter and in the official chiahub.io Discord announcements at least 24 hours prior to going into effect.
            </p>
            <p class="lead">By joining chiahub.io’s pool, you agree to the distribution methodology (PPLNS), the schedule of fees including the means by which it is calculated and collected, and the payout schedule.
            </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Fees"
}
</script>

<style scoped>

</style>
